<template>
  <div v-loading="listLoading">
    <div class="card">
      <el-button type="primary" @click="dialogVisible = true">
        <i class="el-icon-plus"></i>
        新增今日金价
      </el-button>
    </div>
    <div class="list">
      <div class="item" v-for="item in list" :key="item.gold_price_id">
        <div class="top">
          <div class="title">{{item.attr_value || '0.00'}}<span style="margin-left:20px;font-size:14px" v-if="item.brand_name!==''">{{item.brand_name}}</span></div>
          <el-button type="primary" plain icon="el-icon-edit-outline" size="mini" style="width: 80px; height: 40px;" @click="handleEdit(item)">修改</el-button>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="block">
              <span class="name">销售金价</span>
              <span class="money"><span>￥</span>{{item.sale_gold_price || '0.00'}}</span>
            </div>
            <div class="block">
              <span class="name">销售提成金价</span>
              <span class="money"><span>￥</span>{{item.sale_gold_commission || '0.00'}}</span>
            </div>
          </div>
          <div class="right">
            <div class="block">
              <span class="name">回购金价</span>
              <span class="money"><span>￥</span>{{item.recycle_gold_price || '0.00'}}</span>
            </div>
            <div class="block">
              <span class="name">回收提成金价</span>
              <span class="money"><span>￥</span>{{item.recycle_gold_commission || '0.00'}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      :title="form.gold_price_id? '编辑今日金价': '新增今日金价'"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form :model="form" label-width="100px">
        <el-form-item label="材质成色" required v-if="!form.gold_price_id">
          <el-select v-model="form.attr_value_id" clearable filterable>
              <template v-for="item in materialList">
                <el-option :label="item.attr_value" :key="item.attr_value_id" :value="item.attr_value_id"></el-option>
              </template>
            </el-select>
        </el-form-item>
        <el-form-item label="销售金价" required>
          <el-input v-model="form.sale_gold_price"></el-input>
        </el-form-item>

         <el-form-item label="品牌"  v-if="!form.gold_price_id">
           <el-select v-model="form.brand_id">
             <el-option :key="item.brand_id" :label="item.brand_name " :value="item.brand_id"  v-for="item in brandList">
             </el-option>
           </el-select>
        </el-form-item>

        <el-form-item label="销售提成金价">
          <el-input v-model="form.sale_gold_commission"></el-input>
        </el-form-item>
        <el-form-item label="回购金价">
          <el-input v-model="form.recycle_gold_price"></el-input>
        </el-form-item>
        <el-form-item label="回收提成金价">
          <el-input v-model="form.recycle_gold_commission"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="handleTrue">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { getMaterialListReq } from '@/api/goods/RFID/list';
import { createReq, getListReq, getMaterialList as getMaterialListReq,getBrandList} from '@/api/system/goldPrice/list';

export default {
  data() {
    return {
      listLoading: false,
      loading: false,
      dialogVisible: false,
      form: {},
      materialList: [],
      list: [],
      page: 1,
      limit: 999999,
      brandList:[]
    };
  },
  created() {
    this.getMaterialList();
    this.getList();
    this.getBrandLists()
  },
  methods: {
    getBrandLists(){
    this.listLoading = true;
    getBrandList().then(res=>{
        if(res.code==1){
        this.listLoading = false;
      this.brandList=res.data;
      }
 }).catch(err=>{
 this.listLoading=false;
 })
 },

    handleEdit(item) {
      this.form = { ...item };
      if(this.form.brand_id==0){
        this.form.brand_id=""
      }
      this.dialogVisible = true;
    },
    getList() {
      this.listLoading = true;
      getListReq({ page: this.page, limit: this.limit })
        .then((res) => {
          if (res.code === 1) {
            this.listLoading = false;
            this.list = res.data.list;
          }
        })
        .catch(() => {
          this.listLoading = false;
        });
    },
    handleClose() {
      this.dialogVisible = false;
      this.form = {};
    },
    handleTrue() {
      if (!this.form.attr_value_id) {
        this.$message.error('请选择材质成色');
        return;
      }
      if (!this.form.sale_gold_price) {
        this.$message.error('请填写销售金价');
        return;
      }
      this.loading = true;
      createReq({ ...this.form })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            this.$message.success('操作成功');
            this.handleClose();
            this.getList();
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 获取材质成色列表
    getMaterialList() {
      getMaterialListReq()
        .then((res) => {
          if (res.code === 1) {
            this.materialList = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
  .item {
    background: white;
    width: 24.4%;
    height: 165px;
    margin-top: 10px;
    margin-right: 10px;
    border: 1px solid #e8e8e8;
    border-radius: 6px;
    padding: 20px;
    box-sizing: border-box;
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
        font-size: 20px;
        color: #333333;
        font-weight: bold;
        width: 300px;
        margin-right: 20px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .bottom {
      display: flex;
      .left {
        width: 50%;
        border-right: 1px solid #efefef;
        padding-right: 15px;
        box-sizing: border-box;
      }
      .right {
        flex: 1;
        padding-left: 15px;
      }
      .block {
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .name {
          color: #909399;
          font-size: 16px;
        }
        .money {
          font-size: 20px;
          color: #E7541E;
          font-weight: 400;
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
</style>
